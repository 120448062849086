<template>
  <member-setting-container>
    <b-card>
      <!-- Body -->
      <validation-observer>
        <!-- Form -->
        <b-form>
          <!-- Name -->
          <b-row>
            <b-col>
              <b-form-group label-for="message">
                <label class="font-weight-bold">{{
                  $t("backoffice.roles.name")
                }}</label>
                <b-form-input
                  id="message"
                  v-model="form.name"
                  name=" "
                  :placeholder="$t('form.type-placeholder')"
                  rows="8"
                  plaintext
                  rules="required"
                  :state="isValidName"
                  @input="validator()"
                />
                <small v-if="isValidName === false" class="text-danger">
                  {{ $t("backoffice.roles.validator-message") }}
                </small>
              </b-form-group>
              <!-- Tagname -->
              <b-form-group label-for="message">
                <label class="font-weight-bold">{{
                  $t("backoffice.roles.label")
                }}</label>
                <b-form-input
                  id="message"
                  v-model="form.tagName"
                  name=" "
                  :placeholder="$t('form.type-placeholder')"
                  rows="8"
                  rules="required"
                  plaintext
                  :state="isValidTag"
                  @input="validator()"
                />
                <small v-if="isValidTag === false" class="text-danger">
                  {{ $t("backoffice.roles.validator-label") }}
                </small>
              </b-form-group>
              <p class="mb-50 font-weight-bold">Namespace</p>

              <p>
                {{
                  role.namespace === "backoffice"
                    ? $t("backoffice.roles.backoffice")
                    : $t("backoffice.roles.community")
                }}
              </p>
              <p class="mb-50 font-weight-bold">
                {{ $t("backoffice.analytics.data-type") }}
              </p>
              <b-badge
                :variant="role.isGlobal ? 'light-primary' : 'light-info'"
              >
                {{
                  role.isGlobal
                    ? $t("backoffice.roles.global-role")
                    : $t("backoffice.roles.custom")
                }}
              </b-badge>
            </b-col>
            <b-col cols="12" lg="6">
              <!-- Description -->
              <b-form-group label-for="description">
                <label class="font-weight-bold">{{
                  $t("backoffice.roles.description")
                }}</label>
                <p>
                  {{ form.description[locale] || "--" }}
                </p>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
    <b-card v-if="!isLoading">
      <div class="mt-2">
        <h4>{{ $t("backoffice.roles.permissions.title") }}</h4>
      </div>
      <b-row class="mt-1">
        <b-col
          v-for="(rol, index) in enablePermissions"
          :key="index"
          cols="12"
          md="6"
          lg="4"
          xl="3"
          class="mb-2"
        >
          <b-card>
            <h4 class="model-type">
              {{ roleTranslation(rol) }}
            </h4>
            <span
              v-for="(permission, index) in form.permissions"
              :key="index"
              class="d-flex font-weight-bold"
            >
              <span
                v-if="
                  permission.modelType === rol &&
                  (permission.actions || permission.action)
                "
                class="d-flex mb-50"
              >
                <span
                  :class="
                    form.permissions[index].hasPermissions
                      ? 'text-primary'
                      : 'text-secondary'
                  "
                  >{{
                    permissions(permission.action || permission.actions)
                  }}</span
                >
              </span>
            </span>
          </b-card>
        </b-col>
      </b-row>
    </b-card>
    <b-card v-else>
      <b-spinner type="grow" small class="text-primary d-block m-auto" />
    </b-card>
    <unshare-modal
      v-model="isUnshareModalVisible"
      model-type="role"
      @remove-item="handleRemoveItem"
    />
  </member-setting-container>
</template>

<script>
import ToastNotificationsMixin from "@core/mixins/toast-notifications/ToastNotificationsMixin";
import { ValidationObserver } from "vee-validate";
import formValidation from "@core/comp-functions/forms/form-validation";
import { required } from "@validations";
import MemberSettingContainer from "@/views/myself/components/MemberSettingContainer.vue";
import UnshareModal from "@/@core/components/modal/UnshareModal.vue";
import ToastificationContentVue from "@/@core/components/toastification/ToastificationContent.vue";

export default {
  name: "RoleDetail",
  components: {
    ValidationObserver,
    MemberSettingContainer,
    UnshareModal,
  },
  mixins: [ToastNotificationsMixin],
  data() {
    return {
      form: {
        name: null,
        tagName: null,
        description: {},
        permissions: [],
      },
      required,
      existSomeName: false,
      existSomeTag: false,
      isValidTag: null,
      isValidName: null,
      isUnshareModalVisible: false,
      isLoading: true,
    };
  },
  setup() {
    const { getValidationState } = formValidation(() => {});
    return {
      getValidationState,
    };
  },
  computed: {
    currentCollective() {
      return this.$store.getters.currentCollective;
    },
    isDisabled() {
      return this.form.name === "" || this.tagName === "";
      // || !this.form.description;
    },
    role() {
      let rol = this.$store.getters.roles?.unpaginated;

      if (rol.length > 0) {
        return rol.filter(({ key }) => key === this.$route.params.id)[0];
      } else {
        return [];
      }
    },
    total() {
      return this.$store.getters.roles?.meta?.total;
    },
    fields() {
      return [
        {
          key: "fullname",
          label: this.$t("backoffice.roles.name"),
        },
        {
          key: "tagName",
          label: this.$t("backoffice.roles.label"),
        },
      ];
    },
    locale() {
      return this.$store.state.locale.currentLocale;
    },
    currentRole() {
      return this.$route.params.role;
    },
    roleTypes() {
      const duplicatedModelTypes = this.allPermissions
        ?.map((role) => role.modelType)
        .reverse();
      const ModelTypes = new Set(duplicatedModelTypes);
      return [...ModelTypes];
    },
    allPermissions() {
      return this.$store.getters.permissions?.unpaginated.reduce(
        (acc, current) => {
          const x = acc.findIndex((item) => item.key === current.key);
          if (x === -1) {
            return acc.concat([current]);
          }
          acc[x] = current;
          return acc;
        },
        []
      );
    },
    roles() {
      return this.$store.getters.roles.unpaginated;
    },
    enabledApps() {
      // return Object.values(this.$store.getters[ENABLED_APPS_GETTERS.enabledApps]).map(item=> item.nameSingular);
      return this.$store.getters.installedApps.data?.map(
        (item) => item.addonSingular
      );
    },
    enablePermissions() {
      return this.roleTypes.filter((item) => this.enabledApps?.includes(item));
    },
  },
  watch: {
    allPermissions() {
      this.initialize();
    },
  },
  async created() {
    await this.getRoles();
    await this.getInstalledApps();
    this.isLoading = true;
    await this.getPermissions();
  },
  methods: {
    initialize() {
      this.form = {
        name: this.role.name,
        tagName: this.role.tagName,
        description: this.role.description ? this.role.description : {},
        permissions: this.role.permissions,
      };
      const roles = this.form.permissions.map((item) => {
        item.hasPermissions = true;
        return item;
      });
      let todos = [];
      if (this.role.isGlobal) {
        todos = this.allPermissions.filter((item) => {
          if (!this.form.permissions.includes(item)) {
            item.hasPermissions = false;
          }
          return item;
        });
      } else {
        todos = this.allPermissions.filter((item) => {
          if (!this.form.permissions.includes(item)) {
            item.hasPermissions = false;
          }
          return item;
        });
      }

      const ids = new Set(this.role.permissions.map((d) => d.key));
      this.form.permissions = [
        ...roles,
        ...todos.filter((d) => !ids.has(d.key)),
      ];
      this.updateBreadcrumbs();
    },
    async getRoles() {
      await this.$store.dispatch("getRoles", {
        communitySlug: this.$store.getters.currentCollective.slug,
        page: this.$route.params.page,
        perPage: 15,
      });
      this.initialize();
    },
    validator() {
      const existSomeName = this.roles.filter(
        (item) =>
          item.name === this.form.name && this.form.name !== this.role.name
      );
      existSomeName.length > 0
        ? (this.existName = true)
        : (this.existName = false);
      this.isValidName = this.form.name !== "" && !this.existName;
      this.updateBreadcrumbs();
      const existSomeTag = this.roles.filter(
        (item) =>
          item.tagName === this.form.tagName &&
          this.form.tagName !== this.role.tagName
      );
      existSomeTag.length > 0
        ? (this.existTag = true)
        : (this.existTag = false);
      this.isValidTag = !this.existTag;
    },
    async savePermissions() {
      const permissions = [];
      this.form.permissions
        .filter(
          (item) => item.hasPermissions && !this.role.permissions.includes(item)
        )
        .map((item) => permissions.push({ key: item.key }));
      const noPermissions = [];
      this.form.permissions
        .filter(
          (item) => !item.hasPermissions && this.role.permissions.includes(item)
        )
        .map((item) => noPermissions.push({ key: item.key }));
      try {
        await this.$store.dispatch("attachRolePermissions", {
          roleKey: this.role.key,
          permissions,
        });
        await this.$store.dispatch("dettachRolePermissions", {
          roleKey: this.role.key,
          permissions: noPermissions,
        });
        this.notifySuccess(
          this.$t("backoffice.roles.messages.permissions-success")
        );
        this.page = 1;
        const response = await this.getRoles();
        this.initialize();
      } catch {
        this.notifyError(
          this.$t("backoffice.roles.messages.permissions-error")
        );
      }
    },
    updateBreadcrumbs() {
      const breadcrumbs = [
        {
          text: "backoffice.roles.my-roles",
          to: { name: "myRoles" },
        },
        {
          text: this.form.name
            ? this.form.name
            : "backoffice.roles.role-detail",
          active: true,
        },
      ];
      this.$store.commit("app/SET_BREADCRUMBS", breadcrumbs);
    },
    permissions(permission) {
      // TODO: Create constant js
      const transaltedAction = {
        index: this.$t("backoffice.roles.permissions.index"),
        view: this.$t("backoffice.roles.permissions.view"),
        create: this.$t("backoffice.roles.permissions.create"),
        update: this.$t("backoffice.roles.permissions.update"),
        delete: this.$t("backoffice.roles.permissions.delete"),
        invite: this.$t("backoffice.roles.permissions.invite"),
        share: this.$t("backoffice.roles.permissions.share"),
        manage: this.$t("backoffice.roles.permissions.manage"),
        export: this.$t("backoffice.roles.permissions.export"),
        "update customization": this.$t(
          "backoffice.roles.permissions.update-customization"
        ),
        "index payments": this.$t(
          "backoffice.roles.permissions.index-payments"
        ),
        "export metrics": this.$t(
          "backoffice.roles.permissions.export-metrics"
        ),
        "index members": this.$t("backoffice.roles.permissions.index"),
        "view members": this.$t("backoffice.roles.permissions.view"),
        "index registered members": this.$t(
          "backoffice.roles.permissions.index-registered-members"
        ),
        "index members requests": this.$t(
          "backoffice.roles.permissions.index-members-requests"
        ),
        "manage members": this.$t("backoffice.roles.permissions.manage"),
        "manage members requests": this.$t(
          "backoffice.roles.permissions.manage-members-requests"
        ),
        "invite new members": this.$t(
          "backoffice.roles.permissions.invite-new-members"
        ),
        "create members": this.$t("backoffice.roles.permissions.create"),
        "update members": this.$t("backoffice.roles.permissions.update"),
        "delete members": this.$t("backoffice.roles.permissions.delete"),
        "invite members": this.$t("backoffice.roles.permissions.invite"),
        "index forms": this.$t("backoffice.roles.permissions.index"),
        "manage forms": this.$t("backoffice.roles.permissions.manage"),
        "export forms data": this.$t("backoffice.roles.permissions.export"),
        "index roles": this.$t("backoffice.roles.permissions.index"),
        "manage roles": this.$t("backoffice.roles.permissions.manage"),
        "index exams": this.$t("backoffice.roles.permissions.index"),
        "manage exams": this.$t("backoffice.roles.permissions.manage"),
        "export exams data": this.$t("backoffice.roles.permissions.export"),
      };
      return transaltedAction[permission];
    },
    roleTranslation(rol) {
      let namePermission = "";
      this.$store.getters.installedApps.data.map((item) => {
        if (item.addonSingular === rol) {
          namePermission = item.addon[this.locale];
        }
      });
      return namePermission;
    },
    async onSubmit() {
      try {
        await this.$store.dispatch("editRoles", {
          roleKey: this.role.key,
          name: this.form.name,
          description: [this.form.description],
          tagName: this.form.tagName || this.form.name.toLowerCase(),
        });
        this.form.tagName = this.form.tagName || this.form.name.toLowerCase();
        this.notifySuccess(this.$t("backoffice.roles.messages.edit-success"));
      } catch {
        this.notifyError(this.$t("backoffice.roles.messages.edit-error"));
      }
    },
    async handleRemoveItem(response) {
      if (response === true) {
        try {
          await this.$store.dispatch("postItem", {
            type: "roles/delete",
            requestConfig: {
              roleKey: this.role.key,
            },
          });
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: this.$t("unshare.toast-messages.success", {
                item: this.$t("unshare.singular-items.role"),
              }),
              icon: "AlertTriangleIcon",
              variant: "success",
            },
          });
          this.$router.push({
            name: "roles",
            params: { communityId: this.$route.params.communityId },
          });
        } catch {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: this.$t("unshare.toast-messages.error", {
                item: this.$t("unshare.singular-items.role"),
              }),
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        }
      }
    },
    async getInstalledApps() {
      await this.$store.dispatch("getInstalledApps", { getMyRolesInfo: true });
    },
    async getPermissions() {
      this.isLoading = true;
      await this.$store.dispatch("getItems", {
        itemType: "permissions",
        forceAPICall: true,
        requestConfig: {
          namespace: this.role?.namespace,
        },
      });
      this.isLoading = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.model-type h4:first-letter {
  text-transform: uppercase;
}
.share-icon {
  position: absolute;
  margin-top: -60px;
  right: 0;
  cursor: pointer;
}
</style>
